import React, { useState, useEffect } from "react"
import { useLocation } from '@reach/router'
import { graphql, Link } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Loader from "../components/addon/Loader"

import SectionLayout from "../dynamicComponents/sectionLayout"
import BannerComponent from "../dynamicComponents/banner"
import HTMLText from "../dynamicComponents/htmlText"
import HomePageSlider from "../dynamicComponents/homePageSilder"
import TitleDescription from "../dynamicComponents/titleDescription"
import CTALinkModal from "../dynamicComponents/ctaLinkModal"
import ImageWithLink from "../dynamicComponents/imageWithLink"
import TwoColumnWithSeparator from "../dynamicComponents/twoColumnWithSeparator"
import PlayVideoInModal from "../dynamicComponents/playVideoInModal"
import ImageWithDescription from "../dynamicComponents/imageWithDescription"
import ImageTitleDescCard from "../dynamicComponents/imageTitleDescCard"
import ImageComponent from "../dynamicComponents/imageComponent"
import DisplayTextInModal from "../dynamicComponents/displayTextInModal"

import QuotedText from "../components/pageSections/quotedText"
import QuoteVariableText from "../components/pageSections/greyQuotedText"
import Col3Section from "../components/pageSections/homePage/col3Section"
import Col2Section from "../components/pageSections/homePage/col2Section"
import ParaImageWithTitleDesc from "../components/pageSections/paraImageWithTitleDesc"
import TabComponent from "../dynamicComponents/tabComponent"
import TitleModalDisplay from "../dynamicComponents/titleModalDisplay"

const DynamicPages = (props) => {

    const metaTag = []
    const location = useLocation()

    const [pageUrl, setPageUrl] = useState(null)
    const [pageData, setPageData] = useState(null)

    useEffect(() => {
        if(props?.path && props?.data?.allNodeTemplatePages?.edges?.length) {
            let currentPageUrl = props?.path
            setPageUrl(currentPageUrl)
            let data = props?.data?.allNodeTemplatePages?.edges
            let found = data.find(el => el?.node?.path?.alias === currentPageUrl)
            if(found?.node) setPageData(found?.node)
        }
    },[props])

    return (
        <Layout>
            <Meta
                files={{
                    js: [],
                    css: [
                        "/assets/css/common.css", 
                        "/assets/css/listing.css", 
                        "/assets/css/sitemap.css",
                        "/assets/css/accessibility.css",
                        '/assets/css/homepage.css',
                        '/assets/css/dynamic-pages.css', // dynamic css
                        '/assets/css/about.css',
                        '/assets/css/services.css',
                        '/assets/css/online-services.css',
                        '/assets/css/testimonial.css',
                        '/assets/css/about-guiding-principle.css',
                        '/assets/css/academics.css',
                        '/assets/css/research.css',
                    ],
                }}
                tags={pageData?.metatag}
            />
            <main class="innerpage">
                {pageData?.relationships?.components?.length 
                    ? pageData?.relationships?.components.map((component, index) => {
                        switch (component?.section_type) {
                            case 'banner':
                                if(pageData?.relationships?.components?.[index+1]?.section_type === "linkset") {
                                    return <BannerComponent data={component?.relationships?.components?.[0]} linkSetData={pageData?.relationships?.components?.[index+1]} />;
                                } else {
                                    return <BannerComponent data={component?.relationships?.components?.[0]} />;
                                }
                            case 'html_text':
                                return <SectionLayout>
                                    <HTMLText data={component?.relationships?.components?.[0]?.htmlText?.processed} />
                                    </SectionLayout>;
                            case 'quote':
                                return <SectionLayout sectionClass={`about_section`}>
                                    <div className="about_content">
                                        <QuotedText content={component?.relationships?.components?.[0]?.quote} />
                                    </div>
                                </SectionLayout>;
                            case 'quote_variation':
                                return <SectionLayout sectionClass={`about_section about_section_inner`}>
                                    <QuoteVariableText content={component?.relationships?.components?.[0]} />
                                </SectionLayout> 
                            case 'slideshow':
                                return <HomePageSlider data={component?.relationships?.components} />;
                            case '3col':
                                return <section className="three_cards_section">
                                    <Col3Section content={component} />
                                </section>;
                            case 'title_with_desc':
                                return <TitleDescription data={component?.relationships?.components?.[0]} />
                            case '2col_with_separator':
                                return <TwoColumnWithSeparator data={component?.relationships?.components?.[0]} />;
                            case 'cta_with_link_and_modal':
                                return <SectionLayout sectionClass={`section_bg pt-first-section pb-section onlineServiceWrap`}>
                                        <CTALinkModal data={component?.relationships?.components} />
                                    </SectionLayout> 
                            case 'title_and_play_video_in_modal':
                                return <PlayVideoInModal data={component?.relationships?.components} />
                            case 'title_click_modal_desc_display':
                                return <TitleModalDisplay data={component?.relationships?.components} />
                            case 'tab':
                                return <TabComponent data={component?.relationships?.components} />
                            case 'image_with_link':
                                return <SectionLayout>
                                        <ImageWithLink data={component?.relationships?.components?.[0]} />
                                    </SectionLayout> 
                            case 'modal':
                                return <SectionLayout>
                                    <DisplayTextInModal data={component?.relationships?.components} />
                                </SectionLayout>
                            case 'image_left_desc_right':
                                return <section className="section_bg guide_principle_inner">
                                        <div className="container">
                                            <ParaImageWithTitleDesc content={component?.relationships?.components?.[0]} />
                                        </div>
                                    </section>
                            case 'image_right_desc_left':
                                return <section className="section_bg guide_principle_inner">
                                        <div className="container">
                                            <ParaImageWithTitleDesc content={component?.relationships?.components?.[0]} />
                                        </div>
                                    </section>
                            case 'image_with_desc':
                                return <SectionLayout sectionClass={`section_bg pt-first-section pb-section philanthrophy-section`}>
                                    <ImageWithDescription data={component?.relationships?.components?.[0]} />
                                </SectionLayout> 
                            case 'image':
                                return <SectionLayout>
                                    <ImageComponent data={component?.relationships?.components?.[0]} />
                                </SectionLayout>
                            case 'image_with_title_and_desc':
                                return <ImageTitleDescCard data={component?.relationships?.components} />
                            case '4col_with_image_and_link':
                                return <section className="three_cards_section">
                                    <Col3Section content={component} />
                                </section>
                            default:
                                return null;
                        }
                    })
                    : <div>No Data Found.</div>
                }
            </main>
        </Layout>
    )
}

export const qurey = graphql`
query dyanmicPage($page_url: String) {

    allNodeTemplatePages(filter: {
        path: {
            alias: {
                eq: $page_url
            }
        }
    }) {

        edges {

            node {

                id

                title
                field_set_as_dynamic_page
                path {

                    alias

                }

                metatag {
                    attributes {
                        content
                        href
                        name
                        property
                        rel
                    }
                }

                relationships {

                    field_breadcrumb {

                        field_title

                        field_breadcrumb_link {

                            title

                            uri

                        }

                    }

                    field_sitemap_category {

                        name

                        drupal_internal__tid

                    }

                    components: field_component_type {

                        __typename

                            ...ParagraphSectionDynamicPages

                    }

                }

            }

        }

    }

}



fragment ParagraphSectionDynamicPages on paragraph__section {

    id

    name: field_name

    section_type: field_section_type

    relationships {

        components: field_components {

            __typename

                ...ParagraphQuote

                ...ParagraphLinksetDynamicPages

                ...ParagraphImageTitleDescLinkDynamicPages

                ...ParagraphBannerDynamicPages

                ...ParagraphImageWithTitleAndDescriptionDynamicPages

                ...ParagraphSlideshowItemDynamicPages

                ...ParagraphQuote2

                ...ParagraphHTMLText

                ...ParagraphTitleAndDescriptionDynamicPages

                ...ParagraphModal

                ...ParagraphTitleAndDescriptionComponents

                ...ParagraphImageComponentDynamicPages

                ...ParagraphOnlineServices

                ...Paragraph2Col

        }

    }

}



fragment Paragraph2Col on paragraph__2_col {

    id

    relationships {

        field_column_1 {

            ...on paragraph__emergency_info {

                id

                field_title

                field_contact

                field_link {

                    uri

                    title

                }

                relationships {

                    field_icon {

                        uri {

                            value

                            url

                        }

                    }

                }

            }

        }

        field_column_2 {

            field_contact

            field_link {

                title

                uri

            }

            field_title

            relationships {

                field_icon {

                    uri {

                        value

                        url

                    }

                }

            }

        }

    }

}



fragment ParagraphOnlineServices on paragraph__online_services {

    id

    title: field_title

    blurb: field_blurb

    icon: field_icon {

        alt

    }

    link: field_link {

        uri

        title

    }

    relationships {

        icon: field_icon {

            id

            uri {

                value

                url

            }

        }

    }

    field_text {

        processed

    }

}



fragment ParagraphImageComponentDynamicPages on paragraph__image_component {

    id

    image: field_image {

        alt

    }

    mobileImage: field_mobile_image {

        alt

    }

    relationships {

        image: field_image {

            id

            uri {

                url

                value

            }

        }

        mobileImage: field_mobile_image {

            id

            uri {

                url

                value

            }

        }

    }

}



fragment ParagraphHTMLText on paragraph__html_text {

    id

    htmlText: field_text {

        processed

    }

}



fragment ParagraphTitleAndDescriptionComponents on paragraph__title_and_description_components {

    id

    field_name

    field_title

    field_subtitle

    field_link {

        uri

        title

    }

    relationships {

        field_components {

            ...ParagraphTitleAndDescriptionDynamicPages

            ...ParagraphImageTitleDescLinkDynamicPages

            ...ParagraphImageWithTitleAndDescriptionDynamicPages

        }

    }

}



fragment ParagraphModal on paragraph__modal {

    id

    field_title

    field_modal_id

    field_description {

        processed

    }

}



fragment ParagraphQuote on paragraph__quote {

    id

    quote: field_quotation

}



fragment ParagraphBannerDynamicPages on paragraph__banner {

    id

    title: field_title

    subtitle: field_subtitle

    image: field_banner_image {

        alt

    }

    mobileBannerImage: field_mobile_banner {

        alt

    }

    bannerLink: field_banner_link {

        uri

        title

    }

    relationships {

        image: field_banner_image {

            id

            uri {

                value

                url

            }

        }

        mobileBannerImage: field_mobile_banner {

            id

            uri {

                url

                value

            }

        }

    }

}



fragment ParagraphLinksetDynamicPages on paragraph__link_set {

    id

    title: field_title

    subtitle: field_subtitle

    icon: field_icon {

        alt

    }

    relationships {

        icon: field_icon {

            id

            uri {

                url

                value

            }

        }

    }

}



fragment ParagraphImageTitleDescLinkDynamicPages on paragraph__image_title_desc_link {

    id

    title: field_title

    title1: field_title_1

    description: field_description {

        processed

    }

    link: field_link {

        uri

        title

    }

    image: field_image {

        alt

    }

    mobileImage: field_mobile_image {

        alt

    }

    relationships {

        image: field_image {

            id

            uri {

                url

                value

            }

        }

        mobileImage: field_mobile_image {

            id

            uri {

                url

                value

            }

        }

    }

}



fragment ParagraphImageWithTitleAndDescriptionDynamicPages on paragraph__image_with_title_and_description {

    id

    title: field_title

    description: field_content {

        processed

    }

    image: field_image {

        alt

    }

    mobileImage: field_mobile_image {

        alt

    }

    alignment: field_alignment

    relationships {

        image: field_image {

            id

            uri {

                url

                value

            }

        }

        mobileImage: field_mobile_image {

            id

            uri {

                url

                value

            }

        }

        field_department {

            name

        }

        field_pdf_upload {

            uri {

                url

                value

            }

            id

        }

    }

    field_text {

        processed

    }

}



fragment ParagraphSlideshowItemDynamicPages on paragraph__slideshow_item {

    id

    image: field_image {

        alt

    }

    mobileImage: field_mobile_image {

        alt

    }

    link: field_link {

        uri

        title

    }

    relationships {

        image: field_image {

            id

            uri {

                url

                value

            }

        }

        mobileImage: field_mobile_image {

            id

            uri {

                url

                value

            }

        }

    }

}



fragment ParagraphQuote2 on paragraph__quote_variation_2 {

    id

    pre: field_pre_quote

    quote: field_quotation

    post: field_post_quote

}



fragment ParagraphTitleAndDescriptionDynamicPages on paragraph__title_and_description {

    id

    field_title

    text: field_description {

        processed

    }

    field_cta_link {

        title

        uri

    }

    field_text {

        processed

    }

}
`

export default DynamicPages