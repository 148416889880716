import React from "react"
import Slider from "react-slick";

import useDeviceDetect from "../../../service/useDeviceDetect"

const Slider360 = (props) => {

   const { data } = props

   const settings = {
      centerMode: true,
      dots: true,
      lazyLoad: true,
      slidesToShow: 3,
      infinite: data?.length === 1 ? false : true,
      variableWidth: true,
      autoplay: true,
      autoplaySpeed: 3000,
      centerPadding : '0px',
      responsive : false,
      responsive: [{
         breakpoint: 800,
         settings: {
            arrows: true,
            centerMode: false,
            slidesToShow: 1,
            variableWidth: false
         }
      }, {
         breakpoint: 480,
         settings: {
            arrows: true,
            centerMode: false,
            slidesToShow: 1,
            variableWidth: false
         }
      }]
   };

   const { isMobile } = useDeviceDetect()

   const defaultImageSrc = isMobile === true ? "https://via.placeholder.com/260x283" : "https://via.placeholder.com/719x333"

   return (
         <Slider {...settings} className="slick-dotted center-slide">
        {   
            data && data.length > 0 &&
           data.map((el, index) => {
               const imageSrc = isMobile === true ? el?.relationships?.image_mobile?.uri?.url : el?.relationships?.image?.uri?.url
               const altText = el?.image?.alt || "Hinduja Hospital"
              return (
                 <div className="img_slide" key={index}>
                    <figure>                       
                       <picture>
                        {
                           imageSrc ?
                           <img
                              src={imageSrc}
                              alt={altText}
                              className="lazyload" width="719" height="333"
                           />
                           :
                           <img
                              src={defaultImageSrc}
                              alt={altText} />
                        }
                       </picture>
                       <p className="img_overlay">
                       <img src="/assets/images/icons/icon_Virtual_tour.svg" alt="image" className="lazyload" width="719" height="333" />
                          <span> Click Here 360<sup>o</sup> Virtual Tour</span>
                       </p>
                    </figure>
                    {
                       el?.link?.title &&
                       <h2 className="text-left">{el.link.title}</h2>
                    }
                 </div>
              )
           })
        }
     </Slider>
    )
}

export default Slider360
