import React from "react"
import { Link } from "gatsby"

import useDeviceDetect from "../../../../service/useDeviceDetect"

const Col2Section = (props) => {

    const { content } = props

    const { isMobile } = useDeviceDetect()

    const defaultImageSrc = isMobile === true ? "https://via.placeholder.com/330x169" : "https://via.placeholder.com/338x173"

    const cards = (content && content?.relationships && content?.relationships?.components) ? content.relationships.components : {}
    const section1 = cards && cards.length ? cards[0] : null
    const section2 = cards && cards.length ? cards[1] : null

    const imageSrc = section1.relationships?.image?.uri?.url;
    const altText = section1?.image?.alt || "Hinduja Hospital"

    const renderLink = (data) => {
        const cta = data && data.title ? data.title : ''
        let link = data && data.uri ? data.uri : 'javascript:void(0)'
        if (link) {
            if (link.includes('internal:')) {
                const arr = link.split('internal:')
                if (arr && arr.length) {
                    link = arr[1]
                }
            }
        }
        return (
            <Link to={link} className="btn btn-primary">{cta}</Link>
        )
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                {
                    cards.map( (item, i) => {
                        return(<div className="col-md-5 col-lg-4 mb-4 mb-md-0" key={i}>
                            <div className="patient_stories">
                                <h3>{item.title ? item.title : ''}</h3>
                                <figure>
                                <span><img className="lazyload" src="/assets/images/icons/quote_right.svg" alt="Icon" width="60" height="42" /></span>
                                    {
                                        (item?.relationships && item?.relationships?.image?.uri?.url) ?
                                        <img
                                            src={item?.relationships?.image?.uri?.url}
                                            alt={item?.image?.alt || ''} width="338" height="173"
                                        />
                                        :
                                        <img
                                            src={defaultImageSrc}
                                            alt={altText} width="338" height="173" />                                                                 
                                    }
                                </figure>
                                {
                                    (item.description && item.description.processed) &&
                                    <div dangerouslySetInnerHTML={{ __html: item.description.processed }}></div>
                                }
                                <p className="text-center">
                                    {
                                        item.link &&
                                        renderLink(item.link)
                                    }
                                </p>
                            </div>
                        </div>)
                    })
                }
                {/* <div className="col-md-7">
                    {
                        section2 &&
                        <div className="live_to_give_hope">
                            <h3>{section2.title ? section2.title : ''}<br />{ section2.title1 ? section2.title1: ''}</h3>
                            {
                                (section2.description && section2.description.processed) &&
                                <div dangerouslySetInnerHTML={{ __html: section2.description.processed }}></div>
                            }
                            {
                                section2.link &&
                                renderLink(section2.link)
                            }
                        </div>

                    }
                </div> */}
            </div>
        </div>
    )
}

export default Col2Section
